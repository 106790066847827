<template><div><h1 id="faq" tabindex="-1"><a class="header-anchor" href="#faq"><span>FAQ</span></a></h1>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>Please read this page before asking for help. Your help request will be pointed to the docs otherwise.</p>
</div>
<h2 id="general-questions" tabindex="-1"><a class="header-anchor" href="#general-questions"><span>General questions</span></a></h2>
<h3 id="what-os-should-i-use" tabindex="-1"><a class="header-anchor" href="#what-os-should-i-use"><span>What OS should I use?</span></a></h3>
<ul>
<li>This depends on your use case. Only you can answer this question. <!-- Not ubuntu plz --></li>
</ul>
<h3 id="should-i-use-rw-legacy-or-full-rom" tabindex="-1"><a class="header-anchor" href="#should-i-use-rw-legacy-or-full-rom"><span>Should I use rw-legacy or full rom?</span></a></h3>
<ul>
<li>This depends on what you are looking to use your device for. If you are planning on installing Windows or macOS you need to use full rom. Rw-legacy only supports linux.</li>
</ul>
<h3 id="my-internal-keyboard-doesn-t-work-can-i-use-an-external-keyboard-to-get-into-developer-mode" tabindex="-1"><a class="header-anchor" href="#my-internal-keyboard-doesn-t-work-can-i-use-an-external-keyboard-to-get-into-developer-mode"><span>My internal keyboard doesn't work, can I use an external keyboard to get into developer mode?</span></a></h3>
<ul>
<li>No. The hotkeys will only work on the internal keyboard. Chromeboxes do not have this restriction.</li>
</ul>
<h3 id="i-just-want-to-boot-linux-from-usb-on-my-chromebook-what-do-i-need-to-do" tabindex="-1"><a class="header-anchor" href="#i-just-want-to-boot-linux-from-usb-on-my-chromebook-what-do-i-need-to-do"><span>I just want to boot Linux from USB on my Chromebook, what do I need to do?</span></a></h3>
<ul>
<li>Check the Supported Devices page, to ensure your device has functional RW_LEGACY firmware available. If so, flash RWL (RW_LEGACY firmware) and use that.</li>
</ul>
<h3 id="can-i-disable-power-on-when-i-open-my-chromebook" tabindex="-1"><a class="header-anchor" href="#can-i-disable-power-on-when-i-open-my-chromebook"><span>Can I disable power-on when I open my Chromebook?</span></a></h3>
<ul>
<li>No. In what situation would you open your Chromebook without the intention of turning it on anyways.</li>
</ul>
<h3 id="my-chromebook-powers-back-on-when-i-turn-it-off-how-can-i-fix-this" tabindex="-1"><a class="header-anchor" href="#my-chromebook-powers-back-on-when-i-turn-it-off-how-can-i-fix-this"><span>My Chromebook powers back on when I turn it off. How can I fix this?</span></a></h3>
<ul>
<li>You Do not. Specific Chromebooks have this bug which takes too much effort to debug. If you were looking to disable power-on when you open the lid because of this, they are completely unrelated.</li>
</ul>
<h3 id="i-just-installed-the-uefi-firmware-and-now-my-device-boots-to-a-black-screen-that-says-shell-what-do-i-do" tabindex="-1"><a class="header-anchor" href="#i-just-installed-the-uefi-firmware-and-now-my-device-boots-to-a-black-screen-that-says-shell-what-do-i-do"><span>I just installed the UEFI firmware, and now my device boots to a black screen that says 'shell' - what do I do?</span></a></h3>
<ul>
<li>you are in the EFI shell; the firmware boots there when it can't find a valid UEFI boot device, either externally (USB, SD) or internally. Just type <code v-pre>exit</code> to get back to the the UEFI settings menu.</li>
</ul>
<h3 id="should-i-install-32-or-64-bit-os" tabindex="-1"><a class="header-anchor" href="#should-i-install-32-or-64-bit-os"><span>Should I install 32 or 64-bit OS?</span></a></h3>
<ul>
<li>The UEFI Full ROM firmware only supports 64-bit OS, so be sure to use the 'x86_64' version of whatever OS you want to install. USB media created from 32-bit ISOs will simply fail to boot.</li>
</ul>
<h3 id="i-m-running-legacy-boot-firmware-now-can-i-switch-to-the-uefi-firmware" tabindex="-1"><a class="header-anchor" href="#i-m-running-legacy-boot-firmware-now-can-i-switch-to-the-uefi-firmware"><span>I'm running Legacy boot firmware now - can I switch to the UEFI firmware?</span></a></h3>
<ul>
<li>If you are using SeaBIOS, you will likely need to re-install your operating system. Otherwise it should work fine.</li>
</ul>
<h3 id="help-i-can-t-exit-developer-mode" tabindex="-1"><a class="header-anchor" href="#help-i-can-t-exit-developer-mode"><span>Help! I can't exit Developer Mode</span></a></h3>
<ul>
<li>If you are trying to exit Developer Mode but get the error <code v-pre>WARNING: TONORM prohibited by GBB_FORCE_DEV_SWITCH_ON</code> or find the <code v-pre>Return to secure mode</code> button missing, you need to reset your GBB flags. You can do that by running <a href="https://mrchromebox.tech/#fwscript" target="_blank" rel="noopener noreferrer">MrChromebox's firmware utility script</a> and selecting <code v-pre>3) Set Boot Options (GBB flags)</code>, followed by <code v-pre>5) Reset to factory default</code>.
<ul>
<li>Then exit developer mode by choosing &quot;Return to secure mode&quot; on the bootup screen</li>
</ul>
</li>
</ul>
<h3 id="can-i-modify-the-fan-speed" tabindex="-1"><a class="header-anchor" href="#can-i-modify-the-fan-speed"><span>Can I modify the fan speed?</span></a></h3>
<ul>
<li>Yes, using ectool. To set the fan to auto, run: <code v-pre>ectool autofanctrl</code>. To specify a percentage, run <code v-pre>ectool fanduty [0-100]</code>.</li>
</ul>
<h3 id="can-i-overclock-the-fan" tabindex="-1"><a class="header-anchor" href="#can-i-overclock-the-fan"><span>Can I overclock the fan?</span></a></h3>
<ul>
<li>No.</li>
</ul>
<h3 id="i-want-to-buy-a-chromebook-to-run-windows-or-linux-or-macos-which-should-i-buy" tabindex="-1"><a class="header-anchor" href="#i-want-to-buy-a-chromebook-to-run-windows-or-linux-or-macos-which-should-i-buy"><span>I want to buy a Chromebook to run Windows or Linux or macOS -- which should I buy?</span></a></h3>
<ul>
<li>Any 64 bit Chromebook should work in Windows and Linux. Some require paid drivers (Windows only). For information on macOS requirements, see <RouteLink to="/docs/installing/installing-macos.html">this page</RouteLink>.</li>
</ul>
<br>
<h2 id="windows-questions" tabindex="-1"><a class="header-anchor" href="#windows-questions"><span>Windows questions</span></a></h2>
<h3 id="should-i-use-windows-10-or-11" tabindex="-1"><a class="header-anchor" href="#should-i-use-windows-10-or-11"><span>Should I use Windows 10 or 11?</span></a></h3>
<ul>
<li>Windows 11 is required for TB4 users. If you Do not have Thunderbolt 4, you may use both 10 or 11.</li>
</ul>
<h3 id="can-i-pay-for-windows-drivers-using-paypal" tabindex="-1"><a class="header-anchor" href="#can-i-pay-for-windows-drivers-using-paypal"><span>Can I pay for Windows drivers using PayPal?</span></a></h3>
<ul>
<li>You can pay via PayPal in Patreon.</li>
</ul>
<h3 id="my-balance-shows-zero-on-coolstars-portal-what-should-i-do" tabindex="-1"><a class="header-anchor" href="#my-balance-shows-zero-on-coolstars-portal-what-should-i-do"><span>My balance shows zero on coolstars portal, what should I do?</span></a></h3>
<ul>
<li>It may take up to 48 hours for balance to appear in her portal, though it is normally less then 2.</li>
</ul>
<h3 id="how-do-i-buy-the-windows-audio-thunderbolt-drivers" tabindex="-1"><a class="header-anchor" href="#how-do-i-buy-the-windows-audio-thunderbolt-drivers"><span>How do I buy the Windows audio/Thunderbolt drivers?</span></a></h3>
<ul>
<li>Please see <a href="https://coolstar.org/chromebook/driverlicense/login.html" target="_blank" rel="noopener noreferrer">CoolStar's driver portal</a> for a step by step tutorial on purchasing drivers.</li>
</ul>
<h3 id="can-i-get-avs-or-sof-drivers-for-free" tabindex="-1"><a class="header-anchor" href="#can-i-get-avs-or-sof-drivers-for-free"><span>Can I get AVS or SOF drivers for free?</span></a></h3>
<ul>
<li>No. Only the Linux drivers are free.</li>
</ul>
<h3 id="why-is-there-a-license-error-in-device-manager-after-installing-audio-drivers" tabindex="-1"><a class="header-anchor" href="#why-is-there-a-license-error-in-device-manager-after-installing-audio-drivers"><span>Why is there a license error in Device Manager after installing audio drivers?</span></a></h3>
<ul>
<li>You messed up the license setup. Please refer to <a href="https://coolstar.org/chromebook/driverlicense/login.html" target="_blank" rel="noopener noreferrer">CoolStar's driver portal</a>.</li>
</ul>
<h3 id="will-the-audio-driver-still-work-when-re-installing-windows" tabindex="-1"><a class="header-anchor" href="#will-the-audio-driver-still-work-when-re-installing-windows"><span>Will the audio driver still work when re-installing Windows?</span></a></h3>
<ul>
<li>Yes, on the device the license was created. If the internal drive was replaced, see below.</li>
</ul>
<h3 id="i-upgraded-my-chromebooks-internal-drive-and-my-audio-thunderbolt-4-license-is-not-working-what-do-i-do" tabindex="-1"><a class="header-anchor" href="#i-upgraded-my-chromebooks-internal-drive-and-my-audio-thunderbolt-4-license-is-not-working-what-do-i-do"><span>I upgraded my Chromebooks' internal drive and my audio/Thunderbolt 4 license is not working, what do I do?</span></a></h3>
<ul>
<li>The license is tied to the serial number of your internal drive. <strong>You do not need to buy another license</strong>. Send CoolStar a message through Patreon, and wait for about a week.</li>
</ul>
<h3 id="can-i-use-one-driver-license-across-multiple-devices" tabindex="-1"><a class="header-anchor" href="#can-i-use-one-driver-license-across-multiple-devices"><span>Can I use one driver license across multiple devices?</span></a></h3>
<ul>
<li>No. One license per device.</li>
</ul>
<br>
<h2 id="linux-questions" tabindex="-1"><a class="header-anchor" href="#linux-questions"><span>Linux Questions</span></a></h2>
<h3 id="how-come-audio-isn-t-working-on-ubuntu-ubuntu-forks" tabindex="-1"><a class="header-anchor" href="#how-come-audio-isn-t-working-on-ubuntu-ubuntu-forks"><span>How come audio isn't working on Ubuntu / Ubuntu forks?</span></a></h3>
<ul>
<li>Ubuntu and Ubuntu-based distros may have issues and are not supported, try switching to another distro. Debian is a great alternative if you are used to Ubuntu.</li>
</ul>
<h3 id="why-are-ubuntu-ubuntu-based-distros-not-supported" tabindex="-1"><a class="header-anchor" href="#why-are-ubuntu-ubuntu-based-distros-not-supported"><span>Why are Ubuntu/Ubuntu based distros not supported?</span></a></h3>
<ul>
<li>They consistently break packages.</li>
<li>They have started to force snap on their users.</li>
<li>They have published an LTS distro with a non-LTS kernel.</li>
</ul>
<p>Becuse of these things, Ubuntu is unsupported. We will not help you fix issues on Ubuntu.</p>
<h3 id="what-linux-distros-are-recommended" tabindex="-1"><a class="header-anchor" href="#what-linux-distros-are-recommended"><span>What Linux distros are recommended?</span></a></h3>
<ul>
<li>See <RouteLink to="/docs/installing/installing-linux.html">this page</RouteLink>.</li>
</ul>
<h3 id="how-can-i-get-audio-working-under-linux" tabindex="-1"><a class="header-anchor" href="#how-can-i-get-audio-working-under-linux"><span>How can I get audio working under Linux?</span></a></h3>
<ul>
<li>Please see <a href="https://github.com/WeirdTreeThing/chromebook-linux-audio" target="_blank" rel="noopener noreferrer">this GitHub repo</a></li>
</ul>
<h3 id="how-do-i-get-my-top-row-keys-on-linux-to-act-like-how-they-did-on-chromeos" tabindex="-1"><a class="header-anchor" href="#how-do-i-get-my-top-row-keys-on-linux-to-act-like-how-they-did-on-chromeos"><span>How do I get my top row keys on Linux to act like how they did on chromeOS?</span></a></h3>
<ul>
<li>Follow the instructions <a href="https://github.com/WeirdTreeThing/cros-keyboard-map" target="_blank" rel="noopener noreferrer">in this GitHub repo</a>.</li>
</ul>
<br>
<h2 id="hackintosh-questions" tabindex="-1"><a class="header-anchor" href="#hackintosh-questions"><span>Hackintosh questions</span></a></h2>
<h3 id="can-i-hackintosh-my-celeron-pentium-cpu" tabindex="-1"><a class="header-anchor" href="#can-i-hackintosh-my-celeron-pentium-cpu"><span>Can I hackintosh my Celeron/Pentium CPU?</span></a></h3>
<ul>
<li>No.</li>
</ul>
<h3 id="can-i-hackintosh-an-amd-cpu" tabindex="-1"><a class="header-anchor" href="#can-i-hackintosh-an-amd-cpu"><span>Can I hackintosh an AMD CPU?</span></a></h3>
<ul>
<li>In theory, it might be possible. It has never been tested, and can be considered unsupported.</li>
</ul>
</div></template>


